const en = {
    job: {
        search: {
            uuid: "Task UUID",
            jobTypeDisplayName: "Task Type",
            select: "select",
            createTime: "Create Time",
            to: "to",
            startTime: "Start",
            endTime: "End",
            search: "Search",
            status: "Status"
        },
        table: {
            jobId: "Job Id",
            instanceId: "Instance Id",
            uuid: "Job UUID",
            executeStateDisplayName: "Status",
            executeResultCodeDisplayName: "Result",
            jobTypeDisplayName: "Job Type",
            progress: "Progress",
            title: "Title",
            initDate: "Create Time",
            startDate: "Start Time",
            finishDate: "End Time",
            operation: "Operation",
            retryJob: "Retry",
            getChildJob: "Get Child Job",
            weight: "weight",
            updateJob: "update"
        },
        dialog: {
            updateJob: {
                title: "Update Job Info",
                weightInput: "Please input job weight",
                confirm: "Confirm",
                weight: "Weight"
            }
        },
        info: {
            jobFinishedOrUndergoing: "The task has succeeded or the task is in progress",
            jobRetrying: "The job is retrying"
        },
        error: {
            jobRetryFailed: "Failed to retry task",
            jobListFailed: 'Failed to get task list',
            getChildJobFailed: 'Failed to get child job list '
        },
        status: {
            jobExecuteStateFinish: "finish",
            jobExecuteStateWait: "wait",
            jobExecuteStateWaitChild: "work",
            jobExecuteStateWork: "work",
            jobExecuteStateCancel: "cancel",
            jobExecuteStateUnknown: "unknown",
        },
        jobType: {
            jobTypeTranscode: "transcode",
            jobTypeCompile: "compile",
            jobTypeProjectTranscode: "project transcode",
            jobTypeAudioCompare: "audio compare",
            jobTypeWordSplit: "word split",
            jobTypeVideoSplit: "video split",
            jobTypeInstallMaterial: "install material",
            jobTypeAITag: "AI tag",
            jobTypeUnknown: "unknown",
        },
        result: {
            jobExecuteResultCodeSuccess: "success",
            jobExecuteResultCodeFail: "fail",
            jobExecuteResultCodeUnknown: "",
        }
    },
    welcome: {
        profileData: {
            onlineUserCount: "Online users",
            authServerIps: "Authentication server address",
            bsEditConnectionCount: "Cloud edit server connections",
            liveEditConnectionCount: "Live edit server connections",
            bsEditConcurrentCount: "Cloud edit concurrent",
            liveEditConcurrentCount: "Live edit concurrent",
            authStartDate: "Authorization start time",
            authEndDate: "Authorization end time",
        },
        error: {
            userOnlineCountError: 'Get online user count error',
            bsEditAuthInfoError: 'Failed to obtain NLE authorization information'
        }
    },
    login: {
        form: {
            title: "Cloud Edit Management",
            emailInput: "Please enter email or phone",
            secretInput: "Please enter password",
            login: "Login"
        },
        error: {
            loginFailed: "Login failed, please check username or password"
        }
    },
    header: {
        dialog: {
            categorySetting: "Category settings",
            cancel: "Cancel",
            confirm: "Confirm",
            emptyText: "Empty"
        },
        exit: "Exit",
        success: {
            editCategorySuccess: 'Editing material classification success',
        },
        error: {
            editCategoryFailed: 'Failed to edit material classification',
            addCategoryFailed: 'Failed to add category',
            deleteCategoryFailed: 'Failed to delete category',
            moveCategoryFailed: 'Mobile classification failed',
            getCategoryFailed: 'Failed to get material classification',
        },
        prompt: {
            categoryNameInput: "Please enter a category name",
            hint: "Hint",
            confirm: "Confirm",
            cancel: "Cancel"
        },
        msgbox: {
            deleteCategory: "Delete Category",
            hint: "Hint",
            confirm: "Confirm",
            cancel: "Cancel"
        },
        log: {
            getCategoryFailed: 'getCategoryFailed',
            getCategorySuccess: 'getCategorySuccess',
        },
        button: {
            add: "add",
            delete: "delete",
            moveUp: "moveUp",
            moveDown: "moveDown",
            modify: "modify",
            show: "show",
            hide: "hide",
            inputContent: "input content"
        }
    },
    sideMenu: {
        welcome: "Dashboard",
        material: {
            material: "Material",
            theme: "Theme",
            videoFx: "Videofx",
            specialFilter: "SpecialFilter",
            caption: "Caption",
            sticker: "Sticker",
            transition: "Transition",
            particle: "Particle",
            compoundCaption: "Compound",
            music: "Music",
            font: "Font",
            template: "Template",
            richText: "Richtext",
            clipAnimationVideoFx: "ClipAnimation",
            backgroundImage: "Image",
            captionAnimation: "CaptionAnimation",
            animatedStickerAnimation: "StickerAnimation"
        },
        job: "Task",
        monitor: {
            title: "Monitor",
            service: "Service",
            email: "Email"
        },
        splitVideo: {
            title: "SplitVideo",
            newsSplit: "newsSplit"
        }
    },
    material: {
        search: {
            name: "Name",
            id: "Id",
            category: "Category",
            search: "Search",
            syncMaterialCenter: "Sync",
            upload: "Upload",
            select: "Select"
        },
        table: {
            id: "Id",
            coverUrl: "Cover",
            displayName: "Name",
            categoryCustomDisplayName: "Category",
            kindDisplayName: "Kind",
            supportedAspectRatio: "Ratio",
            lastUpdateTime: "Update time",
            search: "Search",
            statusDisplayName: "Status",
            licenseUrl: "Lic",
            m3u8Url: "M3u8",
            link: "Link",
            orderScore: "Order",
            operation: "Operation",
            edit: "Edit",
            delete: "Delete",
            download: "Download",
            updateDemo: "Update demo",
            updateCover: "Update cover",
        },
        dialog: {
            modifyMaterial: {
                title: "Modify Material",
                id: "Id",
                name: "Name",
                category: "Category",
                status: "Status",
                orderScore: "Order",
                cancel: "Cancel",
                confirm: "Confirm"
            },
            deleteMaterial: {
                title: "Delete Material",
                content:"Confirm delete material",
                cancel: "Cancel",
                confirm: "Confirm"
            },
            uploadMaterial: {
                upload: "Upload",
                package: "Package",
                uploading: "Uploading",
                uploaded: "Uploaded",
                cancel: "Cancel",
                confirm: "Confirm"
            },
            updateCover: {
                title: "Update Cover",
                content: "Drag files here, or click Upload",
                cancel: "Cancel",
                confirm: "Confirm"
            },
            columnSetting: {
                title: "Custom Column",
                id: "Id",
                coverUrl: "Cover",
                displayName: "Name",
                categoryCustomDisplayName: "Category",
                kindDisplayName: "Kind",
                supportedAspectRatio: "Ratio",
                lastUpdateTime: "Update time",
                demoUrl: "Demo",
                statusDisplayName: "Status",
                orderScore: "Order",
                licenseUrl: "Lic",
                cancel: "Cancel",
                confirm: "Confirm"
            }
        },
        success: {
            updateCoverSuccess: "Update cover successfully",
            updateDemoSuccess: "Update demo successfully",
            updateMaterialSuccess: "Update material successfully",
        },
        error: {
            updateCoverFailed: "Update cover successfully",
            updateDemoFailed: "Failed to update demo",
            updateMaterialFailed: "Failed to update material",
            categoryIsNull: "Category cannot be empty",
            searchCategoryFailed: "Currently, it is not supported to query the official website and non-editing classification at the same time",
            getMaterialListFailed: "Failed to get material list",
            getCategoryFailed: "Failed to get category"
        },
        warning: {
            updateDemoWarning: "The maximum number of file uploads has been manipulated",
            materialTypeWarning: 'This material type is not currently supported'
        },
        info: {
            removeDemo: "Make sure to remove the demo file？"
        },
        status: {
            deleted: "Deleted",
            init: "Init",
            transcoding: "Transcoding",
            installing: "Installing",
            normal: "Normal",
            transcodeFailed: "Transcode Failed",
            notDisplay: "Not Display"
        },
        log: {
            uploadProgress: "Upload progress",
            materialLocation: "Material upload address",
            uploadDemoProgress1: "Update the progress of the demo upload 1",
            uploadDemoProgress2: "Update the progress of the demo upload 2",
            materialInfoError: "uuid or packageUrl is empty"
        }
    },
    monitor: {
        search: {
            addService: "Add Service",
            addEmail: "Add Email",
        },
        dialog: {
            addService: {
                title: "Add Service",
                urlInput: "Please input service url",
                typeInput: "Please input service type",
                ipAddressInput: "Please input ip address",
                confirm: "Confirm"
            },
            addEmail: {
                title: "Add service exception notification email",
                emailInput: "Please input your email",
                confirm: "Confirm"
            },
            updateEmail: {
                title: "Update Email",
                emailInput: "Please input your email",
                statusInput: "Please input your status",
                confirm: "Confirm"
            }
        },
        table: {
            update: "Update",
            remove: "Remove",
            downloadServiceLog: "Log",
            operation: "Operation",
            columnId: "Id",
            columnEmail: "Email",
            columnCreatedAt: "Created at",
            columnStatus: "Status",
            columnServiceId: "ID",
            columnServiceUrl: "Address",
            columnServiceType: "Service type",
            columnServiceStatus: "Status",
            columnServiceIpAddress: "Ip address",
            columnServiceLastDate: "LastDate",
            columnServiceExecuteCount: "ExecuteCount",
            columnServiceMaxExecuteCount: "MaxExecuteCount",
            service: {
                authServer: "Auth server",
                taskScheduler: "Task scheduler",
                compile: "Compile",
                transcode: "Transcode",
                splitWord: "Split word",
                audioCompare: "Audio compare",
                splitVideo: "Split video",
                tagRecognition: "Tag recognition",
                aigcImageToVideo: "AIGC image to video",
                aigcSR: "AIGC SR",
            },
            status: {
                normal: "Normal",
                error: "Error"
            },
            email: {
                status: {
                    send: "Send",
                    dontSend: "Dont send"
                }
            }
        }
    },
    splitVideo: {
        search: {
            addNews: "Add News Video",
            search: "Search"
        },
        table: {
            name: "Name",
            type: "Type",
            fileName: "FileName",
            duration: "Duration",
            createdAt: "CreatedAt",
            status: "Status",
            operation: "Operation",
            addClip: "Add",
            deleteClip: "Delete",
            addMiddleClip: "Add Middle Clip",
            deleteMiddleClip: "Delete Middle Clip",
            deleteNews: "Delete News"
        },
        dialog: {
            addNews: {
                title: "Title",
                titleInput: "TitleInput",
                confirm: "Confirm",
            },
            addClip: {
                title: "Title",
                content: "Drag files here, or click Upload",
                cancel: "Cancel",
                confirm: "Confirm"
            }
        },
        clipType: {
            start: "Start",
            middle: "Middle",
            end: "End"
        },
        confirm: {
            message: "This operation will be permanently deleted, do you want to continue?",
            title: "Title",
            confirm: "Confirm",
            cancel: "Cancel"
        },
        status: {
            init: "Init",
            normal: "Normal",
            failed: "Failed",
            hide: "Hide",
            work: "Work",
            deleted: "Deleted"
        }
    }
}

export default en
