const zh = {
    job: {
        search: {
            uuid: "任务UUID",
            jobTypeDisplayName: "任务类型",
            select: "请选择",
            createTime: "创建时间",
            to: "至",
            startTime: "开始日期",
            endTime: "结束日期",
            search: "查询",
            status: "状态"
        },
        table: {
            jobId: "任务Id",
            instanceId: "服务器Id",
            uuid: "任务UUID",
            executeStateDisplayName: "状态",
            executeResultCodeDisplayName: "任务结果",
            jobTypeDisplayName: "任务类型",
            progress: "进度",
            title: "任务名称",
            initDate: "创建时间",
            startDate: "开始时间",
            finishDate: "结束时间",
            operation: "操作",
            retryJob: "重试",
            getChildJob: "查询子任务",
            weight: "权重",
            updateJob: "更新"
        },
        dialog: {
            updateJob: {
                title: "更新任务信息",
                weightInput: "请输入任务权重",
                confirm: "确 定",
                weight: "任务权重"
            }
        },
        info: {
            jobFinishedOrUndergoing: "任务已成功或任务正在进行中",
            jobRetrying: "任务重试中"
        },
        error: {
            jobRetryFailed: "重试任务失败",
            jobListFailed: '获取任务列表失败',
            getChildJobFailed: '获取子任务列表失败'
        },
        status: {
            jobExecuteStateFinish: "完成",
            jobExecuteStateWait: "等待",
            jobExecuteStateWaitChild: "工作",
            jobExecuteStateWork: "工作",
            jobExecuteStateCancel: "取消",
            jobExecuteStateUnknown: "未知",
        },
        jobType: {
            jobTypeTranscode: "转码",
            jobTypeCompile: "合成",
            jobTypeProjectTranscode: "转项目",
            jobTypeAudioCompare: "音频对齐",
            jobTypeWordSplit: "分词",
            jobTypeVideoSplit: "拆条",
            jobTypeInstallMaterial: "安装特效包",
            jobTypeAITag: "标签识别",
            jobTypeUnknown: "未知",
        },
        result: {
            jobExecuteResultCodeSuccess: "成功",
            jobExecuteResultCodeFail: "失败",
            jobExecuteResultCodeUnknown: "",
        }
    },
    welcome: {
        profileData: {
            onlineUserCount: "在线用户数",
            authServerIps: "鉴权服务器地址",
            bsEditConnectionCount: "云剪服务器连接数",
            liveEditConnectionCount: "直播剪服务器连接数",
            bsEditConcurrentCount: "云剪并发数量",
            liveEditConcurrentCount: "直播剪并发数量",
            authStartDate: "授权开始时间",
            authEndDate: "授权结束时间",
        },
        error: {
            userOnlineCountError: '获取在线用户数错误',
            bsEditAuthInfoError: '获取非编授权信息失败'
        }
    },
    login: {
        form: {
            title: "云剪管理平台",
            emailInput: "请输入邮箱或电话",
            secretInput: "请输入密码",
            login: "登录"
        },
        error: {
            loginFailed: "登录失败, 请检查用户名或密码"
        }
    },
    header: {
        dialog: {
            categorySetting: "分类设置",
            cancel: "取 消",
            confirm: "确 定",
            emptyText: "暂无数据"
        },
        exit: "退出",
        success: {
            editCategorySuccess: '编辑素材分类成功',
        },
        error: {
            editCategoryFailed: '编辑素材分类失败',
            addCategoryFailed: '添加分类失败',
            deleteCategoryFailed: '删除分类失败',
            moveCategoryFailed: '移动分类失败',
            getCategoryFailed: '获取素材分类失败',
        },
        prompt: {
            categoryNameInput: "请输入分类名称",
            hint: "提示",
            confirm: "确定",
            cancel: "取消"
        },
        msgbox: {
            deleteCategory: "确定删除分类",
            hint: "提示",
            confirm: "确定",
            cancel: "取消"
        },
        log: {
            getCategoryFailed: '重新获取分类失败',
            getCategorySuccess: '重新获取分类成功',
        },
        button: {
            add: "添加",
            delete: "删除",
            moveUp: "向上",
            moveDown: "向下",
            modify: "修改",
            show: "显示",
            hide: "隐藏",
            inputContent: "请输入内容"
        }
    },
    sideMenu: {
        welcome: "首页",
        material: {
            material: "素材",
            theme: "主题",
            videoFx: "滤镜",
            specialFilter: "特效",
            caption: "字幕",
            sticker: "贴纸",
            transition: "转场",
            particle: "粒子",
            compoundCaption: "复合字幕",
            music: "音乐",
            font: "字体",
            template: "模板",
            richText: "富文本字幕",
            clipAnimationVideoFx: "片段动画",
            backgroundImage: "背景图片",
            captionAnimation: "字幕动画",
            animatedStickerAnimation: "贴纸动画"
        },
        job: "任务",
        monitor: {
            title: "监控",
            service: "服务",
            email: "邮箱"
        },
        splitVideo: {
            title: "智能拆条",
            newsSplit: "新闻拆条"
        }
    },
    material: {
        search: {
            name: "素材名称",
            id: "素材Id",
            category: "分类",
            search: "查询",
            syncMaterialCenter: "同步官网",
            upload: "上传",
            select: "请选择"
        },
        table: {
            id: "素材Id",
            coverUrl: "封面",
            displayNamezhCN: "名称",
            categoryCustomDisplayName: "二级分类",
            kindDisplayName: "三级分类",
            supportedAspectRatio: "分辨率",
            lastUpdateTime: "更新时间",
            search: "查看",
            statusDisplayName: "状态",
            licenseUrl: "授权地址",
            m3u8Url: "m3u8地址",
            link: "链接",
            orderScore: "素材排序",
            operation: "操作",
            edit: "编辑",
            delete: "删除",
            download: "下载",
            updateDemo: "更新demo",
            updateCover: "替换封面",
        },
        dialog: {
            modifyMaterial: {
                title: "修改素材",
                id: "素材Id",
                name: "素材名称",
                category: "非编分类",
                status: "素材状态",
                orderScore: "素材排序",
                cancel: "取 消",
                confirm: "确 定"
            },
            deleteMaterial: {
                title: "提示",
                content:"确认删除素材",
                cancel: "取 消",
                confirm: "确 定"
            },
            uploadMaterial: {
                upload: "上传",
                package: "包",
                uploading: "上传中",
                uploaded: "上传完",
                cancel: "取 消",
                confirm: "确 定"
            },
            updateCover: {
                title: "更改封面",
                content: "将文件拖到此处，或点击上传",
                cancel: "取 消",
                confirm: "确 定"
            },
            columnSetting: {
                title: "自定义列",
                id: "素材ID",
                coverUrl: "封面",
                displayName: "名称",
                categoryCustomDisplayName: "二级分类",
                kindDisplayName: "三级分类",
                supportedAspectRatio: "分辨率",
                lastUpdateTime: "更新时间",
                demoUrl: "Demo",
                statusDisplayName: "状态",
                orderScore: "素材排序",
                licenseUrl: "授权地址",
                cancel: "取 消",
                confirm: "确 定"
            }
        },
        success: {
            updateCoverSuccess: "更新封面成功",
            updateDemoSuccess: "更新demo成功",
            updateMaterialSuccess: "更新素材成功",
        },
        error: {
            updateCoverFailed: "更新封面成功",
            updateDemoFailed: "更新demo失败",
            updateMaterialFailed: "更新素材失败",
            categoryIsNull: "分类不能为空",
            searchCategoryFailed: "暂不支持同时查询官网和非编分类",
            getMaterialListFailed: "获取素材列表失败",
            getCategoryFailed: "获取分类失败"
        },
        warning: {
            updateDemoWarning: "操作了最大文件上传个数",
            materialTypeWarning: '该素材类型暂不支持'
        },
        info: {
            removeDemo: "确定移除demo文件？"
        },
        status: {
            deleted: "已删除",
            init: "初始状态",
            transcoding: "转码进行中",
            installing: "安装进行中",
            normal: "正常",
            transcodeFailed: "转码失败",
            notDisplay: "云剪不展示"
        },
        log: {
            uploadProgress: "上传进度",
            materialLocation: "素材上传地址",
            uploadDemoProgress1: "更新demo上传的进度1",
            uploadDemoProgress2: "更新demo上传的进度2",
            materialInfoError: "uuid或packageUrl为空"
        }
    },
    monitor: {
        search: {
            addService: "添加服务",
            addEmail: "添加邮箱",
        },
        dialog: {
            addService: {
                title: "添加服务",
                urlInput: "请输入服务地址",
                typeInput: "请输入类型",
                ipAddressInput: "请输入IP地址",
                confirm: "确定"
            },
            addEmail: {
                title: "添加服务异常通知邮箱",
                emailInput: "请输入邮箱",
                confirm: "确定"
            },
            updateEmail: {
                title: "更新邮箱",
                emailInput: "请输入邮箱",
                statusInput: "请输入状态",
                confirm: "确定"
            }
        },
        table: {
            update: "更新",
            remove: "删除",
            downloadServiceLog: "日志",
            operation: "操作",
            columnId: "ID",
            columnEmail: "邮箱",
            columnCreatedAt: "创建时间",
            columnStatus: "状态",
            columnServiceId: "ID",
            columnServiceUrl: "地址",
            columnServiceType: "服务类型",
            columnServiceStatus: "状态",
            columnServiceIpAddress: "IP地址",
            columnServiceLastDate: "最后通信时间",
            columnServiceExecuteCount: "当前任务数",
            columnServiceMaxExecuteCount: "并发任务数",
            service: {
                authServer: "鉴权服务",
                taskScheduler: "任务调度服务",
                compile: "合成服务",
                transcode: "转码服务",
                splitWord: "分词服务",
                audioCompare: "音频对齐服务",
                splitVideo: "视频拆条",
                tagRecognition: "标签识别",
                aigcImageToVideo: "AIGC图片转视频",
                aigcSR: "AIGC SR",
            },
            status: {
                normal: "正常",
                error: "异常"
            },
            email: {
                status: {
                    send: "发送",
                    dontSend: "不发送"
                }
            }
        }
    },
    splitVideo: {
        search: {
            addNews: "添加节目信息",
            search: "查询"
        },
        table: {
            name: "节目名称",
            type: "数据类型",
            fileName: "文件名称",
            duration: "文件时长",
            createdAt: "上传日期",
            status: "状态",
            operation: "操作",
            addClip: "添加",
            deleteClip: "删除",
            addMiddleClip: "添加片花",
            deleteMiddleClip: "删除片花",
            deleteNews: "删除节目"
        },
        dialog: {
            addNews: {
                title: "添加节目",
                titleInput: "添加节目名称",
                confirm: "确认",
            },
            addClip: {
                title: "添加片段",
                content: "将文件拖到此处，或点击上传",
                cancel: "取消",
                confirm: "确定"
            }
        },
        clipType: {
            start: "片头",
            middle: "片花",
            end: "片尾"
        },
        confirm: {
            message: "此操作将永久删除, 是否继续?",
            title: "提示",
            confirm: "确认",
            cancel: "取消"
        },
        status: {
            init: "初始化",
            normal: "正常",
            failed: "失败",
            hide: "隐藏",
            work: "资源处理中",
            deleted: "已删除"
        }
    }
}

export default zh
