<template>
  <el-main>
    <el-card>
      <el-form :model="search">
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ $t('monitor.search.addService') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="serviceList" style="width: 100%" :empty-text="$t('header.dialog.emptyText')">
        <el-table-column
            :sortable="false"
            align="center"
            v-for="header in headers"
            :key="header.prop"
            :prop="header.prop"
            :label="header.label"
            :min-width="header.minWidth"
            :width="header.width">
        </el-table-column>
        <el-table-column
            align="center"
            :min-width="100"
            :label="$t('monitor.table.columnServiceStatus')">
          <template slot-scope="scope">
            <el-tag :class="scope.row.status.toLowerCase() == 'normal' ? 'normal' : 'error'">{{scope.row.statusDisplayName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         :label="$t('monitor.table.operation')"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-button @click="removeService(scope.row)" type="text" size="small">{{ $t('monitor.table.remove') }}</el-button>
            <el-link size="small" style="font-size:12px;margin-left: 10px;line-height: 32px;" type="primary"
                     :href="getLogLink(scope.row)" target="_blank">
              {{ $t('monitor.table.downloadServiceLog') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="$t('monitor.dialog.addService.title')" width="30%"
               :close-on-click-modal="false"
               :visible.sync="addServiceDialogVisible"
               :destroy-on-close="true">
      <el-form :model="serviceInfo">
        <el-form-item>
          <el-input :placeholder="$t('monitor.dialog.addService.urlInput')" v-model="serviceInfo.url" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="serviceInfo.type" clearable :placeholder="$t('monitor.dialog.addService.typeInput')"
                     style="width: 100%;float: left;">
            <el-option
                v-for="item in serviceTypesAdd"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input :placeholder="$t('monitor.dialog.addService.ipAddressInput')" v-model="serviceInfo.ipAddress" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmService" style="width: 100%;">{{$t('monitor.dialog.addService.confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import NvNetwork from "@/api/NvNetwork.js";
import NvUtils from "@/api/NvUtils.js";

export default {
  name: "NvMonitor",
  data() {
    return {
      search: {},
      addServiceDialogVisible: false,
      serviceInfo: {
        url: "",
        type: "",
      },
      serviceList: [],
      headers: [
        {prop: 'id', label: this.$t('monitor.table.columnServiceId'), minWidth: 100},
        {prop: 'url', label: this.$t('monitor.table.columnServiceUrl'), minWidth: 100},
        {prop: 'typeDisplayName', label: this.$t('monitor.table.columnServiceType'), minWidth: 100},
        {prop: 'ipAddress', label: this.$t('monitor.table.columnServiceIpAddress'), minWidth: 100},
        {prop: 'lastDate', label: this.$t('monitor.table.columnServiceLastDate'), minWidth: 100},
        {prop: 'executeCount', label: this.$t('monitor.table.columnServiceExecuteCount'), minWidth: 100},
        {prop: 'maxExecuteCount', label: this.$t('monitor.table.columnServiceMaxExecuteCount'), minWidth: 100},
      ],
      serviceTypes: [
        {value: "auth_server", label: this.$t('monitor.table.service.authServer')},
        {value: "task_scheduler", label: this.$t('monitor.table.service.taskScheduler')},
        {value: "compile", label: this.$t('monitor.table.service.compile')},
        {value: "transcode", label: this.$t('monitor.table.service.transcode')},
        {value: "split_word", label: this.$t('monitor.table.service.splitWord')},
        {value: "audio_compare", label: this.$t('monitor.table.service.audioCompare')},
        {value: "split_video", label: this.$t('monitor.table.service.splitVideo')},
        {value: "tag_recognition", label: this.$t('monitor.table.service.tagRecognition')},
        {value: "aigc_img2video", label: this.$t('monitor.table.service.aigcImageToVideo')},
        {value: "aigc_sr", label: this.$t('monitor.table.service.aigcSR')},
      ],
      serviceTypesAdd: [
        {value: "auth_server", label: this.$t('monitor.table.service.authServer')},
        {value: "task_scheduler", label: this.$t('monitor.table.service.taskScheduler')},
      ],
      serviceStatus: [
        {value: "normal", label: this.$t('monitor.table.status.normal')},
        {value: "error", label: this.$t('monitor.table.status.error')},
      ]
    }
  },
  mounted() {
    this.getServiceList()
  },
  methods: {
    confirmService() {
      let network = new NvNetwork()
      network.monitorServiceSave(null, this.serviceInfo.url, this.serviceInfo.type, this.serviceInfo.ipAddress).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: response.message,
            type: 'success'
          })
        } else {
          this.$message.error(response.message)
        }
        this.getServiceList()
      }).catch(error => {
        console.log(error)
      })
      this.addServiceDialogVisible = false
    },
    onSubmit() {
      this.addServiceDialogVisible = true
    },
    getServiceList() {
      let network = new NvNetwork()
      network.monitorServiceList().then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.serviceList = response.data
          this.serviceList.forEach((item, index) => {
            this.serviceStatus.forEach(status => {
              if (item.status.toLowerCase() == status.value) {
                item.statusDisplayName = status.label
              }
            })
            this.serviceTypes.forEach(type => {
              if (item.type.toLowerCase() == type.value) {
                item.typeDisplayName = type.label
              }
            })
            item.lastDate = NvUtils.timestampToTime(item.lastDate/1000)
            if (NvUtils.isEmpty(item.executeCount)) {
              item.executeCount = "-"
            }
            if (NvUtils.isEmpty(item.maxExecuteCount)) {
              item.maxExecuteCount = "-"
            }
            this.$set(this.serviceList, index, item)
          })
        } else {
          this.$message.error(response.message)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    removeService(serviceInfo) {
      let network = new NvNetwork()
      network.monitorServiceRemove(serviceInfo.id).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: response.message,
            type: 'success'
          })
        } else {
          this.$message.error(response.message)
        }
        this.getServiceList()
      }).catch(error => {
        console.log(error)
      })
    },
    getLogLink(serviceInfo) {
      let network = new NvNetwork()
      return "https://editor.meishesdk.com:8082/log/export?address=" + serviceInfo.ipAddress +  "&type=" + serviceInfo.type
    }
  }
}
</script>

<style scoped>
.el-main {
  width: 200px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
.el-card {
  margin-bottom: 20px;
}
::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px;
}
.normal {
}
.error {
  color: red;
}
</style>
