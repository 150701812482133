import COS from 'cos-js-sdk-v5'
import OSS from 'ali-oss'
import AWS from 'aws-sdk'
import axios from 'axios'
import NvUtils from './NvUtils'
import NvEnum from "@/api/NvEnum";
import {streamUpload} from "@/api/NvSmgUploader.js";

const service = axios.create({})

export const post = ({url, data, headers}) => {
    return service.post(url, data, headers).then(res => {
        return res.data
    })
}

export default class NvNetwork {
    static materialTypeTheme = 1;
    static materialTypeFilter = 2;
    static materialTypeCaption = 3;
    static materialTypeAnimatedSticker = 4;
    static materialTypeTransition = 5;
    static materialTypeFont = 6;
    static materialTypeMusic = 7;
    static materialTypeParticle = 9;
    static materialTypeARScene = 14;
    static materialTypeCompoundCaption = 15;
    static materialTypePhotoAlbum = 16;
    static materialTypeMIMO = 17;
    static materialTypeVlog = 18;
    static materialTypeTemplate = 19;
    static materialTypeRichText = 100;
    static materialTypeClipAnimationVideoFx = 54;
    static materialTypeBackgroundImage = 8;
    static materialTypeSpecialFilter = 10;
    static materialTypeCaptionAnimation = 11;
    static materialTypeAnimatedStickerAnimation = 12;
    static materialSubTypeMusicAudioEffect = 1;
    static materialSubTypeMusicMoodType = 2;

    static resourceTypeVideo = 1000
    static resourceTypeImage = 1001
    static resourceTypeAudio = 1002
    static resourceTypeSplitVideoClip = 1003

    static responseCodeSuccess = 0;
    static responseCodeFail = -1;
    static responseCodeInvalidToken = -2

    // 页面ID，根据接口控制侧边栏菜单的显示
    static pageId_home = 100
    static pageId_publicMaterial = 200
    static pageId_materialTheme = 201
    static pageId_materialFilter = 202
    static pageId_materialCaption = 203
    static pageId_materialAnimatedSticker = 204
    static pageId_materialTransition = 205
    static pageId_materialParticle = 206
    static pageId_materialCaptureScene = 207
    static pageId_materialARScene = 208
    static pageId_materialCompoundCaption = 209
    static pageId_materialPhotoAlbum = 210
    static pageId_materialMIMO = 211
    static pageId_materialVLog = 212
    static pageId_materialTemplate = 213
    static pageId_materialFont = 214
    static pageId_materialMusic = 215
    static pageId_materialClipAnimationVideoFx = 216
    static pageId_materialBackgroundImage = 217
    static pageId_materialCaptionAnimation = 218
    static pageId_materialAnimatedStickerAnimation = 219
    static pageId_job = 300
    static pageId_splitVideo = 301

    static buttonId_categorySetting = 1000

    static materialTypes = [{value: 1, label: "主题", labelZhCN: "主题"},
        {value: 2, label: "video fx", labelZhCN: "滤镜"},
        {value: 3, label: "caption", labelZhCN: "字幕"},
        {value: 4, label: "sticker", labelZhCN: "贴纸"},
        {value: 5, label: "transition", labelZhCN: "转场"},
        {value: 9, label: "particle", labelZhCN: "粒子"},
        {value: 14, label: "ARScene", labelZhCN: "ARScene"},
        {value: 15, label: "compound caption", labelZhCN: "复合字幕"},
        {value: 16, label: "photo album", labelZhCN: "影集"},
        {value: 17, label: "MIMO", labelZhCN: "MIMO"},
        {value: 18, label: "VLog", labelZhCN: "VLog"},
        {value: 19, label: "template", labelZhCN: "模板"},
        {value: 100, label: "rich text", labelZhCN: "富文本字幕"},
        {value: 8, label: "special effects", labelZhCN: "特效"},
        {value: 10, label: "image", labelZhCN: "背景图片"},
        {value: 11, label: "caption animation", labelZhCN: "字幕动画"},
        {value: 12, label: "sticker animation", labelZhCN: "贴纸动画"},
    ];

    static jobTypes = [
        {value: 0, label: "job.jobType.jobTypeTranscode", labelZhCN: "转码"},
        {value: 1, label: "job.jobType.jobTypeCompile", labelZhCN: "合成"},
        {value: 3, label: "job.jobType.jobTypeProjectTranscode", labelZhCN: "转项目"},
        {value: 4, label: "job.jobType.jobTypeAudioCompare", labelZhCN: "音频对齐"},
        {value: 5, label: "job.jobType.jobTypeWordSplit", labelZhCN: "分词"},
        {value: 6, label: "job.jobType.jobTypeVideoSplit", labelZhCN: "拆条"},
        {value: 7, label: "job.jobType.jobTypeInstallMaterial", labelZhCN: "安装特效包"},
        {value: 9, label: "job.jobType.jobTypeAITag", labelZhCN: "标签识别"},
    ]

    static jobStatus = [
        {value: "FINISH", label: "job.status.jobExecuteStateFinish"},
        {value: "WAIT", label: "job.status.jobExecuteStateWait"},
        {value: "WORK", label: "job.status.jobExecuteStateWork"},
        {value: "CANCEL", label: "job.status.jobExecuteStateCancel"},
    ]

    static materialStatus = [
        {value: 0, label: "initialize", labelZhCN: "初始状态"},
        {value: 1, label: "normal", labelZhCN: "正常"},
        {value: 2, label: "transcode failed", labelZhCN: "转码失败"},
        {value: 3, label: "not display", labelZhCN: "云剪不展示"},
        {value: 100, label: "deleted", labelZhCN: "已删除"},
    ]

    static categoryLevel1 = 1
    static categoryLevel2 = 2
    static categoryLevel3 = 3

    static jobExecuteStateWait = "WAIT"
    static jobExecuteStateWaitChild = "WAIT_CHILD"
    static jobExecuteStateWork = "WORK"
    static jobExecuteStateFinish = "FINISH"
    static jobExecuteStateCancel = "CANCEL"

    static jobExecuteResultCodeSuccess = 0
    static jobExecuteResultCodeFail = 1

    static jobTypeTranscode = 0
    static jobTypeCompile = 1
    static jobTypeProjectTranscode = 3
    static jobTypeAudioCompare = 4
    static jobTypeWordSplit = 5
    static jobTypeVideoSplit = 6
    static jobTypeInstallMaterial = 7
    static jobTypeAITag = 9

    // SMG 上传相关接口
    static uploadMountHost = getStorageCDNHost()
    // 闻歌 相关配置项
    static defaultApiVersion = 'V1.0'
    static apiVersionV2_0 = 'V2.0'

    // 上传类型
    static uploadTypeAliyun = "aliyun"
    static uploadTypeQiniu = "qiniu"
    static uploadTypeTencent = "tencent"
    static uploadTypeAws = "aws"
    static uploadTypeLocal = "local"
    static uploadTypeWenge = "wenge"
    static uploadTypeHuaweicloud = "huaweicloud"
    static uploadTypeKsyun = "ksyun"
    static uploadTypeSmg = "smg"
    static uploadTypeUcloud = "ucloud"
    static uploadTypeOracle = "oracle"
    static uploadTypeCmbchina = "cmbchina"

    data() {
        return {
            // 上传及打包任务相关
            webApi_sts_info2: getApiHost() + '/upload/sts/info',
            webApi_job_info: getApiHost() + '/job/info',

            // 用户相关
            webApi_user_list: getApiHost() + '/user/list',
            webApi_login: getApiHost() + '/login',

            // 素材相关
            webApi_material_sync: getApiHost() + '/material/sync',
            webApi_material_add: getApiHost() + '/material/add',
            webApi_material_delete: getApiHost() + '/material/delete',

            // 获取所有素材分类
            webApi_material_category_all: getApiHost() + '/materialCategory/all',
            webApi_material_category_all_new: getApiHost() + '/materialCategoryNew/all',
            // 编辑素材分类
            webApi_material_category_edit: getApiHost() + '/materialCategory/edit',
            webApi_material_category_edit_new: getApiHost() + '/materialCategoryNew/edit',
            // 添加素材分类
            webApi_material_category_add: getApiHost() + '/materialCategory/add',
            webApi_material_category_add_new: getApiHost() + '/materialCategoryNew/add',
            // 删除素材分类
            webApi_material_category_delete: getApiHost() + '/materialCategory/delete',
            webApi_material_category_delete_new: getApiHost() + '/materialCategoryNew/delete',
            // 移动分类顺序
            webApi_material_category_move: getApiHost() + '/materialCategory/move',
            webApi_material_category_move_new: getApiHost() + '/materialCategoryNew/move',
            // 获取素材一级分类(20210804新增)
            webApi_material_category_first_level_new: getApiHost() + '/materialCategoryNew/firstLevel',
            // 获取素材分类
            webApi_material_categories: getApiHost() + '/material/categories',
            webApi_material_categories_new: getApiHost() + '/materialNew/back/categories',
            // 获取素材列表
            webApi_material_list: getApiHost() + '/material/back/list',
            webApi_material_list_new: getApiHost() + '/materialNew/back/list',
            // 更新素材
            webApi_material_update: getApiHost() + '/material/update',
            webApi_material_update_new: getApiHost() + '/materialNew/update',
            // 更新素材封面(20210804新增)
            webApi_material_update_cover_new: getApiHost() + '/materialNew/updateCover',
            // 删除素材(20210804新增)
            webApi_material_delete_new: getApiHost() + '/materialNew/delete',
            // 素材添加
            webApi_material_add_new: getApiHost() + '/materialNew/add',
            // 添加富文本字幕
            webApi_material_add_rich_text: getApiHost() + '/materialNew/addFreeWord',
            // 更新素材demo
            webApi_material_update_demo: getApiHost() + '/materialNew/updateDemo',

            // 统计在线人数统计
            webApi_user_online_count: getApiHost() + '/user/onlineCount',
            // 统计鉴权器的授权信息
            webApi_bs_edit_auth_info: getAuthApiHost() + '/page/authInfo',

            // SMG 上传相关接口
            upload_stream: getUploadHost(),
            // 任务列表
            webApi_job_list: getJobApiHost() + '/list',
            // 任务重试
            webApi_job_retry: getJobApiHost() + '/retry',

            // 添加邮箱
            webApi_monitor_email_add: getMonitorApiHost() + '/monitor/email/add',
            // 更新邮箱信息
            webApi_monitor_email_update: getMonitorApiHost() + '/monitor/email/update',
            // 删除邮箱信息
            webApi_monitor_email_remove: getMonitorApiHost() + '/monitor/email/remove',
            // 邮箱列表
            webApi_monitor_email_list: getMonitorApiHost() + '/monitor/email/list',
            // 保存要监控的服务
            webApi_monitor_service_save: getMonitorApiHost() + '/monitor/service/save',
            // 移除监控的服务
            webApi_monitor_service_remove: getMonitorApiHost() + '/monitor/service/remove',
            // 监控服务状态列表
            webApi_monitor_service_list: getMonitorApiHost() + '/monitor/service/list',
            // log
            webApi_monitor_service_log: getMonitorApiHost() + '/log/export',

            // 查询子任务接口
            webApi_job_children_list: getJobApiHost() + '/children/list',
            // 修改任务权重接口
            webApi_job_update_weight: getJobApiHost() + '/update/weight',

            // 添加新闻
            webApi_splitvideo_demo_add: getApiHost() + '/splitVideo/demo/add',
            // 新闻列表
            webApi_splitvideo_demo_list: getApiHost() + '/splitVideo/demo/list',
            // 新闻移除
            webApi_splitvideo_demo_remove: getApiHost() + '/splitVideo/demo/remove',
            // 新闻片段添加
            webApi_splitvideo_clip_add: getApiHost() + '/splitVideo/clip/add',
            // 新闻片段移除
            webApi_splitvideo_clip_remove: getApiHost() + '/splitVideo/clip/remove',

            // ucloud token url
            webApi_ucloud_token_url: getApiHost() + '/upload/ucloud',
            // webApi_ucloud_token_url: getApiHost() + '/upload/upload/ucloud',
        }
    }
    handleApiMessage(responseData) {
        if (NvUtils.isEmpty(responseData.message) && !NvUtils.isEmpty(responseData.msg)) {
            responseData.message = responseData.msg
        }
        return responseData
    }
    // 添加新闻
    async splitVideoDemoAdd(demoType, title) {
        let url = this.data().webApi_splitvideo_demo_add
        let response = await axios.post(url, null,{
            params: {
                demoType: demoType,
                title: title
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 新闻列表
    async splitVideoDemoList(page, pageSize) {
        let url = this.data().webApi_splitvideo_demo_list
        let response = await axios.post(url, null,{
            params: {
                page: page,
                pageSize: pageSize
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 新闻移除
    async splitVideoDemoRemove(demoId) {
        let url = this.data().webApi_splitvideo_demo_remove
        let response = await axios.post(url, null, {
            params: {
                demoId: demoId
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 新闻片段添加
    async splitVideoClipAdd(clipId, url, title, clipType) {
        let clipAdd = this.data().webApi_splitvideo_clip_add
        let response = await axios.post(clipAdd, null,{
            params: {
                clipId: clipId,     // 调用sts info返回的objectId
                url: url,
                title: title,
                clipType: clipType
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 新闻片段移除
    async splitVideoClipRemove(clipId) {
        let url = this.data().webApi_splitvideo_clip_remove
        let response = await axios.post(url, null,{
            params: {
                clipId: clipId
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 修改任务权重接口
    async jobUpdateWeight(uuid, weight) {
        let url = this.data().webApi_job_update_weight
        let response = await axios.get(url, {
            params: {
                uuid: uuid,
                weight: weight
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })
        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 查询子任务接口
    async jobChildrenList(jobId) {
        let url = this.data().webApi_job_children_list
        let response = await axios.get(url, {
            params: {
                uuid: jobId
            },
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 保存要监控的服务
    async monitorServiceSave(id, serviceUrl, type, ipAddress) {
        let url = this.data().webApi_monitor_service_save
        let param = {
            url: serviceUrl,
            type: type
        }
        if (!NvUtils.isEmpty(id)) {
            param['id'] = id
        }
        if (!NvUtils.isEmpty(ipAddress)) {
            param['ipAddress'] = ipAddress
        }

        let response = await axios.post(url, JSON.stringify(param), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 移除监控的服务
    async monitorServiceRemove(id) {
        let url = this.data().webApi_monitor_service_remove + "?id=" + id
        let response = await axios.post(url, null, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 监控服务状态列表
    async monitorServiceList() {
        let url = this.data().webApi_monitor_service_list
        let response = await axios.get(url, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 添加邮箱
    async monitorEmailAdd(email) {
        let url = this.data().webApi_monitor_email_add
        let data = {
            email: email
        }
        let response = await axios.post(url, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 更新邮箱信息
    async monitorEmailUpdate(id, email, status) {
        let url = this.data().webApi_monitor_email_update
        let data = {
            id: id,
            email: email,
            status: status
        }
        let response = await axios.post(url, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 删除邮箱信息
    async monitorEmailRemove(id) {
        let url = this.data().webApi_monitor_email_remove
        let data = {
            id: id,
        }
        let response = await axios.post(url, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 邮箱列表
    async monitorEmailList() {
        let url = this.data().webApi_monitor_email_list
        let response = await axios.get(url, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 更新素材demo
    async updateDemo(id, demoUrl) {
        let url = this.data().webApi_material_update_demo
        let param = {
            id: id,
            demoUrl: demoUrl
        }

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 添加富文本字幕
    async addRichText(originUuid, uuid, defaultRichTextImageUrl, defaultRichTextHtmlUrl, defaultRichTextInfoJsonUrl) {
        let url = this.data().webApi_material_add_rich_text
        let param = {
            originUuid: originUuid,
            uuid: uuid,
            defaultRichTextImageUrl: defaultRichTextImageUrl,
            defaultRichTextHtmlUrl: defaultRichTextHtmlUrl,
            defaultRichTextInfoJsonUrl: defaultRichTextInfoJsonUrl
        }

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 更新素材封面
    async updateMaterialCover(id, coverUrl) {
        let url = this.data().webApi_material_update_cover_new
        let param = {
            id: id,
            coverUrl: coverUrl
        }

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 重试任务
    async retryJob(uuid) {
        let url = this.data().webApi_job_retry
        let param = {
            uuid: uuid
        }
        let response = await axios.get(url, {
            params: param,
            headers: {
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 获取任务列表
    async getJobList(pageNum, pageSize, uuid, jobType, initDateStart, initDateEnd, executeState) {
        let url = this.data().webApi_job_list
        let param = {
            pageNum: pageNum,
            pageSize: pageSize,
            showDetail: true
        }
        if (!NvUtils.isEmpty(uuid)) {
            param['uuid'] = uuid
        }
        if (!NvUtils.isEmpty(jobType)) {
            param['type'] = jobType
        }
        if (!NvUtils.isEmpty(initDateStart) && !NvUtils.isEmpty(initDateEnd)) {
            param['initDateStart'] = initDateStart
            param['initDateEnd'] = initDateEnd
        }
        if (!NvUtils.isEmpty(executeState)) {
            param['executeState'] = executeState
        }

        let response = await axios.get(url, {
            params: param,
            headers: {
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 统计鉴权器的授权信息
    async getBsEditAuthInfo() {
        let url = this.data().webApi_bs_edit_auth_info
        let response = await axios.get(url, {
            headers: {
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 统计在线人数统计
    async getUserOnlineCount(startTime, endTime) {
        let url = this.data().webApi_user_online_count
        let param = {
            start: startTime,
            end: endTime
        }
        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async moveMaterialCategory(id, materialType, isCustom, level, orderScore, parentType) {
        let url = ""
        let param = {}

        url = this.data().webApi_material_category_move_new
        param = {
            id: id,
            parentType: parentType,
            orderScore: orderScore
        };

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async deleteMaterialCategory(id, materialType, isCustom, level) {
        let url = ""
        let param = {}
        url = this.data().webApi_material_category_delete_new
        param = {
            id: id
        };

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async addMaterialCategory(id, materialType, isCustom, displayNamezhCN, displayName, level, parentType) {
        let url = ""
        let param = {}
        url = this.data().webApi_material_category_add_new
        param = {
            parentType: parentType,
            displayNamezhCN: displayNamezhCN,
            displayName: displayName,
            level: level,
        };

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async editMaterialCategory(id, materialType, isCustom, displayNamezhCN, displayName, level, visibility) {
        let url = ""
        let param = {}
        url = this.data().webApi_material_category_edit_new
        param = {
            id: id,
            displayNamezhCN: displayNamezhCN,
            displayName: displayName,
            visibility: visibility
        };

        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async getAllMaterialCategory() {
        let url = this.data().webApi_material_category_all_new

        let response = await axios.get(url, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async deleteMaterial(id) {
        let url = this.data().webApi_material_delete_new
        let param = {
            id: id
        };
        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 转换素材类型
    convertMaterialType(materialType) {
        return (parseInt(materialType) == NvNetwork.materialTypeRichText) ? NvNetwork.materialTypeTemplate : parseInt(materialType)
    }

    // 添加素材
    async addMaterial(materialType, uuid, packageUrl, coverUrl, displayName, displayNameZhCN, ratio, ratioFlag,
                      licenseUrl, category, categoryCustom, demoUrl, materialDuration, supportedAspectRatio, infoUrl, fontUrls, assetUrls,
                      defaultAspectRatio) {
        let url = this.data().webApi_material_add_new
        let data = {
            uuid: uuid,
            packageUrl: packageUrl,
            coverUrl: coverUrl,
            displayName: displayName,
            displayNameZhCN: displayNameZhCN,
            materialType: this.convertMaterialType(materialType),
            licenseUrl: licenseUrl,
            category: category,
            categoryCustom: categoryCustom,
            demoUrl: demoUrl,
            duration: materialDuration,
            supportedAspectRatio: supportedAspectRatio,
            infoUrl: infoUrl,
            fontUrls: fontUrls,
            assetUrls: assetUrls,
            defaultAspectRatio: defaultAspectRatio
        }
        let response = await axios.post(url, JSON.stringify(data), {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 同步官网素材
    async syncMaterial(materialType) {
        let url = this.data().webApi_material_sync
        let param = {
            type: materialType
        }
        let response = await axios.get(url, {
            params: param,
            headers: {
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 获取素材分类
    async getMaterialCategories(materialType, isCustom) {
        let url = ""
        let param = {}
        let searchType = this.convertMaterialType(materialType)
        url = this.data().webApi_material_categories_new
        param = {
            type: searchType
        }

        let response = await axios.get(url, {
            params: param,
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 获取素材列表
    async getMaterialList(pageNum, pageSize, materialType, id, category, isCustom, aspectRatio, startTime, endTime, materialSubType, keyword, kindId) {
        let url = this.data().webApi_material_list_new

        let param = {
            type: this.convertMaterialType(materialType),
            page: pageNum - 1,
            pageSize: pageSize,
        }
        if (!NvUtils.isEmpty(aspectRatio)) {
            param['aspectRatio'] = aspectRatio
        }
        if (!NvUtils.isEmpty(startTime) && !NvUtils.isEmpty(endTime)) {
            param['startTime'] = startTime
            param['endTime'] = endTime
        }
        if (!NvUtils.isEmpty(keyword)) {
            param['keyword'] = keyword
        }
        if (!NvUtils.isEmpty(id)) {
            param['id'] = id
        }
        if (!NvUtils.isEmpty(category)) {
            param['category'] = category
        }
        if (!NvUtils.isEmpty(isCustom)) {
            param['isCustom'] = isCustom
        }
        if (!NvUtils.isEmpty(status)) {
            param['status'] = 100
        }
        // if (!NvUtils.isEmpty(materialSubType)) {
        //     param['subType'] = materialSubType
        // }
        if (!NvUtils.isEmpty(kindId)) {
            param['kindId'] = kindId
        }

        let response = await axios.get(url, {
            params: param,
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    // 获取用户列表
    async getUserList(pageNum, pageSize, phone, nickname, startTime, endTime) {
        const url = this.data().webApi_user_list
        let param = {
            page: pageNum,
            pageSize: pageSize,
        }
        if (!NvUtils.isEmpty(phone)) {
            param['phone'] = phone
        }
        if (!NvUtils.isEmpty(nickname)) {
            param['nickname'] = nickname
        }
        if (!NvUtils.isEmpty(startTime)) {
            param['startTime'] = startTime
        }
        if (!NvUtils.isEmpty(endTime)) {
            param['endTime'] = endTime
        }
        let response = await axios.get(url, {
            params: param,
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        let userList = this.resolveResponseData(response)
        return new Promise((resolve) => {
            resolve(userList)
        })
    }

    // 更新素材信息
    async updateMaterial(id, categoryCustom, demoUrl, status, displayName, displayNamezhCN, coverUrl, kindId, orderScore) {
        let url = this.data().webApi_material_update_new
        let param = {
            id: id,
            categoryCustom: categoryCustom,
            demoUrl: demoUrl,
            status: parseInt(status),
            displayName: displayName,
            displayNamezhCN: displayNamezhCN
        };
        if (!NvUtils.isEmpty(coverUrl)) {
            param['coverUrl'] = coverUrl
        }
        if (!NvUtils.isEmpty(kindId)) {
            param['kindId'] = kindId
        }
        if (!NvUtils.isEmpty(orderScore)) {
            param['orderScore'] = orderScore
        }
        let response = await axios.post(url, param, {
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    resolveResponseData(response) {
        if (response.data.code == NvNetwork.responseCodeFail) {
            console.log("Failed to resolve response data: " + response.data.msg)
            return response.data.code
        } else if (response.data.code == NvNetwork.responseCodeSuccess) {
            return NvUtils.isEmpty(response.data.data) ? response.data.code : response.data.data
        } else if (response.data.code == NvNetwork.responseCodeInvalidToken) {
            localStorage.userToken = ""
            // router.push('/login')
        }
        return null
    }

    async login(loginName, pass, cb) {
        const url = this.data().webApi_login
        const data = {
            loginName: loginName,
            password: pass,
            // appId: getAppId(),
            // isSubAccount: false
        }
        let response = await axios.post(url, data,{
            headers: {
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        return new Promise((resolve) => {
            response.data =  NvUtils.deepCopy(this.handleApiMessage(response.data))
            resolve(response.data)
        })
    }

    async getJobInfo(jobId) {
        let url = this.data().webApi_job_info
        let param = {
            id: jobId
        }
        let response = await axios.get(url, {
            params: param,
            headers: {
                Authorization: localStorage.userToken,
                'X-Request-Id': NvUtils.generateUUID()
            }
        })

        let userList = this.resolveResponseData(response)
        return new Promise((resolve) => {
            resolve(userList)
        })
    }

    upload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        if (getUploadType() == NvNetwork.uploadTypeQiniu) {
            this.qiniuUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeAliyun) {
            this.aliyunUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeTencent) {
            this.tencentUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeAws) {
            this.awsUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeLocal) {
            this.localUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeWenge) {
            this.wengeUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeHuaweicloud) {
            this.huaweicloudUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeKsyun) {
            this.ksyunUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeSmg) {
            this.smgUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeUcloud) {
            this.ucloudUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeOracle) {
            this.oracleUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        } else if (getUploadType() == NvNetwork.uploadTypeCmbchina) {
            this.cmbchinaUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId)
        }
    }

    async cmbchinaUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.cmbchina,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId,
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        let responseData = response.data.data
        let relativePath = responseData.relativePath

        await axios({
            method: 'put',
            url: relativePath,
            data: file,
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        })

        let trimmedUrl = relativePath.substring(0, relativePath.indexOf("?"))
        callback({
            keyUUID: uuid,
            key: relativePath,
            progress: 100,
            uploadFinish: true,
            Location: trimmedUrl
        })
    }

    async oracleUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.oracle,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId,
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        let responseData = response.data.data
        let relativePath = responseData.relativePath
        let preRequestUri = responseData.preRequestUri

        const ociUrl = responseData.endPoint
        await axios({
            method: 'put',
            url: `${ociUrl}${preRequestUri}`,
            data: file,
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        })

        callback({
            keyUUID: uuid,
            key: relativePath,
            progress: 100,
            uploadFinish: true,
            Location: getStorageCDNHost() + '/' + relativePath
        })
    }

    async ucloudUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        let fileType = NvUtils.getHttpContentType(extension)
        let newFile = new File([file], filename, {type: fileType})
        const requestParams = {
            type: NvEnum.ossType.ucloud,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId,
            ucloudParam: {
                contentType: newFile.type,
                method: 'PUT'
            }
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        let responseData = response.data.data
        let relativePath = responseData.relativePath

        let ufile =  new UCloudUFile(responseData.bucket, responseData.endPoint, this.data().webApi_ucloud_token_url);

        let data = {
            file: newFile,
            fileRename: relativePath,
            key: relativePath
        };

        let successCallBack = function(res) {
            console.info("successCallBack", res)
            let callbackData = {}
            callbackData.keyUUID = uuid
            callbackData.uploadFinish = true
            callbackData.progress = 100
            callbackData.key = relativePath
            callbackData.Location = getStorageCDNHost() + '/' + relativePath
            console.log(JSON.stringify(callbackData))
            callback(callbackData)
        };
        let progressCallBack = function(res) {
            let callbackData = {}
            callbackData.keyUUID = uuid
            callbackData.uploadFinish = false
            callbackData.progress = Math.round(res.value * 100)
            callbackData.key = relativePath
            callbackData.Location = getStorageCDNHost() + '/' + relativePath
            callbackData.objectId = responseData.objectId
            console.log(JSON.stringify(callbackData))
            callback(callbackData)
        };

        let errorCallBack = function(res){
            console.error("errorCallBack", res)
        };

        ufile.uploadFile(data, successCallBack, errorCallBack, progressCallBack);
    }

    async smgUpload(materialType, filename, uuid, file, callback, error, changeFileName) {
        // 上传素材的key的路径规则是material/<type>/<uuid>/<uuid>.<ext>
        let ext = NvUtils.getFileExt(filename)
        let key = NvUtils.getMaterialPackageKey(materialType) + uuid + "/" + uuid + "." + ext
        if (NvUtils.hasMaterialVersion(filename)) {
            let version = NvUtils.getMaterialVersion(filename)
            key = NvUtils.getMaterialPackageKey(materialType) + uuid + "/" + uuid + "." + version + "." + ext
        }
        streamUpload(key, file, progress => {
            let data = []
            data.keyUUID = uuid
            data.uploadFinish = false
            data.progress = parseInt(progress.progress)
            data.key = key
            data.Location = ""
            callback(data)
        }).then(url => {
            let data = []
            data.keyUUID = uuid
            data.uploadFinish = true
            data.progress = 100
            data.key = key
            data.Location = url
            callback(data)
        })
    }

    async awsUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.amazon,
            extension: extension,
            isNeedCallback: 0, // 亚马逊上传  没有回调
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        response = response.data.data

        let s3 = new AWS.S3({
            credentials: {
                accessKeyId: response.accessKeyId,
                secretAccessKey: response.secretAccessKey,
                sessionToken: response.securityToken
            },
            region: response.region
        })
        let key = response.relativePath
        console.log("上传的key ", key)
        let params = {
            Bucket: response.bucket,
            Key: key,
            Body: file,
            ACL: 'public-read'
        }
        s3.upload(params, (err, sendData) => {
            console.log(err || sendData)
            if (sendData) {
                sendData.keyUUID = uuid
                sendData.uploadFinish = true
                sendData.progress = 100
                sendData.key = key
                sendData.Location = getStorageCDNHost() + '/' + key
                callback(sendData)
            }
        }).on('httpUploadProgress', function (data) {
            data.keyUUID = uuid
            data.uploadFinish = false
            data.progress = Math.round((data.loaded * 100) / data.total)
            data.key = key
            data.Location = getStorageCDNHost() + '/' + key
            data.objectId = response.objectId
            console.log(JSON.stringify(data))
            callback(data)
        })
    }

    async localUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.local,
            extension: extension,
            isNeedCallback: 1,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        let responseData = response.data.data

        let formData = new FormData()
        formData.append('files', file)
        let key = responseData.relativePath
        formData.append('keys', key)
        axios.post(getUploadHost(), formData, {
            headers: {Authorization: localStorage.userToken}
        }).then(response => {
            console.log(response)
            if (response.data.code == 0) {
                let progressData = {}
                progressData.keyUUID = uuid
                progressData.progress = 100
                progressData.key = key
                progressData.filename = filename
                progressData.uploadFinish = true
                progressData.Location = getStorageCDNHost() + '/' + key
                progressData.objectId = responseData.objectId
                callback(progressData)
            } else {
                error("Failed to upload " + filename)
            }
        }).catch(err => {
            console.log(err)
            error(err)
        })
    }

    async wengeUpload(materialType, filename, uuid, file, callback, error, changeFileName) {
        let formData = new FormData()
        formData.append('files', file)
        // 上传的文件名 <uuid>.<ext>
        formData.append('filename', filename)
        // 上传素材的key的路径规则是material/<type>/<uuid>/<uuid>.<ext>
        let ext = NvUtils.getFileExt(filename)
        let key = "material/" + NvUtils.getMaterialPackageKey(materialType) + uuid
        formData.append('key', key)
        axios({
            url: getUploadHost(),
            method: 'post',
            data: formData
        }).then(response => {
            console.log(response)
            if (response.data.success) {
                let progressData = {}
                progressData.keyUUID = uuid
                progressData.progress = 100
                progressData.key = key
                progressData.filename = filename
                progressData.uploadFinish = true
                progressData.Location = response.data.data[0]
                callback(progressData)
            } else {
                error("Failed to upload " + filename)
            }
        }).catch(err => {
            console.log(err)
            error(err)
        })
    }

    isUploadHideType(materialType) {
        return materialType == NvNetwork.materialTypeClipAnimationVideoFx ||
            materialType == NvNetwork.materialTypeCaptionAnimation ||
            materialType == NvNetwork.materialTypeAnimatedStickerAnimation ||
            materialType == NvNetwork.materialTypeSpecialFilter
    }

    async ksyunUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.ksyun,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        response = response.data.data

        let ak = response.accessKeyId
        let sk = response.secretAccessKey
        let bucket = response.bucket
        let region = response.region
        let securityToken = response.securityToken
        let key = response.relativePath

        // let ak = "AKLTJQ7LzftyQqWRnOsmc-v2PQ"
        // let sk = "OLyaa6HxRqZIE8iiT0JfcXaXYtrNNjUYSAN1Iv2FfgfDsNtUuzGPI4PY8woWd401wQ=="
        // let bucket = "live-edit"
        // let region = "BEIJING"
        // let securityToken = ""
        // let key = filename

        multipartUpload({
            AK: ak,
            SK: sk,
            Bucket: bucket,
            Region: region,
            Key: key,
            ACL: 'public-read',
            File: file,
            TotalSize: file.size,
            Signature: '',
        }, function (err, res) {
            if (err) {
                if (err.msg != 'stop') {
                    console.error(err);
                    error(err)
                } else {
                    console.log(err);
                }
            } else {
                console.log('res: ' + JSON.stringify(res));
                let progressData = {}
                progressData.keyUUID = uuid
                progressData.progress = 100
                progressData.key = key
                progressData.filename = file.name
                progressData.uploadFinish = true
                progressData.Location = getStorageCDNHost() + '/' + key
                progressData.objectId = response.objectId
                callback(progressData)
            }
        }, function (progress) {
            console.log("ksyun progress: ", progress)
            let progressData = {}
            progressData.keyUUID = uuid
            progressData.progress = progress
            progressData.key = key
            progressData.filename = file.name
            progressData.uploadFinish = false
            progressData.Location = ""
            callback(progressData)
        });
    }

    async huaweicloudUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.huaweicloud,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        response = response.data.data

        let ak = response.accessKeyId
        let sk = response.secretAccessKey
        let securityToken = response.securityToken
        let server = response.endpoint
        let obsClient = new ObsClient({
            access_key_id: ak,
            secret_access_key: sk,
            server: server,
            timeout: 60 * 5,
            security_token: securityToken
        });

        let key = response.relativePath
        obsClient.uploadFile({
            Bucket: response.bucket,
            Key: key,
            Metadata: {property: 'property-value'},
            SourceFile: file,
            TaskNum: 3,
            ProgressCallback: function (transferredAmount, totalAmount, totalSeconds) {
                let progressData = {}
                progressData.keyUUID = uuid
                progressData.progress = Math.round(transferredAmount * 100.0 / totalAmount)
                progressData.key = key
                progressData.filename = file.name
                progressData.uploadFinish = false
                progressData.Location = ""
                callback(progressData)
            }
        }).then(function (result) {
            console.log('CommonMsg:');
            console.log('Status-->' + result.CommonMsg.Status);
            console.log('Code-->' + result.CommonMsg.Code);
            console.log('Message-->' + result.CommonMsg.Message);
            console.log('HostId-->' + result.CommonMsg.HostId);
            console.log('InterfaceResult:');
            if (result.InterfaceResult) {
                console.log('RequestId-->' + result.InterfaceResult.RequestId);
                console.log('ETag-->' + result.InterfaceResult.ETag);
                let progressData = {}
                progressData.keyUUID = uuid
                progressData.progress = 100
                progressData.key = key
                progressData.filename = file.name
                progressData.uploadFinish = true
                progressData.Location = getStorageCDNHost() + '/' + key
                progressData.objectId = response.objectId
                callback(progressData)
            }
        }).catch(function (err) {
            console.error(err);
            error(err)
        });
    }

    async aliyunUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.ali,
            extension: extension,
            isNeedCallback: 1,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        response = response.data.data

        const callbackInfo = response.callbackInfo || {}
        const callbackObj = {
            url: callbackInfo.callbackUrl,
            body: callbackInfo.callbackBody,
            contentType: callbackInfo.callbackBodyType
        }

        let client = new OSS({
            region: response.region,
            accessKeyId: response.accessKeyId,
            accessKeySecret: response.secretAccessKey,
            stsToken: response.securityToken,
            bucket: response.bucket,
            cname: true,
            endpoint: getAliyunOSSEndPoint()
        })
        let key = response.relativePath
        console.log("上传的key ", key)
        let finish = false
        const progress = (p, _checkpoint) => {
            let progressData = {}
            progressData.keyUUID = uuid
            progressData.progress = Math.round(p * 100)
            progressData.key = key
            progressData.filename = file.name
            progressData.uploadFinish = Math.round(p * 100) == 100 ? true : false
            progressData.Location = getStorageCDNHost() + '/' + key
            progressData.objectId = response.objectId
            if (progressData.uploadFinish) {
                // 防止完成上传后，触发两次创建资源
                if (!finish) {
                    callback(progressData)
                    finish = true
                }
            } else {
                callback(progressData)
            }
        };

        // 开始分片上传。
        async function multipartUpload() {
            try {
                let fileOrBlob = file
                if (typeof file == 'string') {
                    fileOrBlob = new Blob([file], {type: "text/plain;charset=utf-8"});
                }
                // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                console.log("分片上传key ", key)
                const result = await client.multipartUpload(key, fileOrBlob, {
                    progress,
                    callback: callbackObj
                });
            } catch (e) {
                // 捕获超时异常。
                if (e.code === 'ConnectionTimeoutError') {
                    console.log('TimeoutError');
                    // do ConnectionTimeoutError operation
                }
                error(e)
            }
        }

        multipartUpload();
    }

    async tencentUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.tencent,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("sts info response ", response)
        response = response.data.data

        let cos = new COS({
            SecretId: response.accessKeyId,
            SecretKey: response.secretAccessKey,
            XCosSecurityToken: response.securityToken
        })

        let key = response.relativePath
        console.log("上传的key ", key)
        cos.putObject({
            Bucket: response.bucket,
            Region: response.region,
            Key: key,
            Body: file,
            onProgress: function (progressData) {
                progressData.uploadFinish = false
                progressData.key = key
                console.log(JSON.stringify(progressData));
                callback(progressData)
            }
        }, function (err, data) {
            console.log(err || data)
            if (data) {
                data.uploadFinish = true
                data.key = key
                data.Location = getStorageCDNHost() + '/' + key
                data.objectId = response.objectId
                console.log(JSON.stringify(data));
                callback(data)
            }
        })
    }

    async qiniuUpload(materialType, filename, uuid, file, callback, error, changeFileName, objectId) {
        let extension = NvUtils.getFileExt(filename)
        const requestParams = {
            type: NvEnum.ossType.qiniu,
            extension: extension,
            isNeedCallback: 0,
            uuid: uuid,
            uploadModule: NvUtils.getUploadModuleName(materialType),
            isDir: 0,
            changeFileName: changeFileName,
            objectId: objectId
        }
        if (this.isUploadHideType(materialType)) {
            requestParams.hideType = 1
        }
        const url = this.data().webApi_sts_info2
        let response = await axios.post(url, JSON.stringify(requestParams), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: localStorage.userToken
            }
        })
        console.log("qiniuUpload sts info ", response)
        response = response.data.data
        let key = response.relativePath
        let upToken = response.securityToken;
        console.log("qiniuUpload response ", key, upToken)

        // eslint-disable-next-line
        let finishedAttr = [];
        // eslint-disable-next-line
        let compareChunks = [];

        // 设置next,error,complete对应的操作，分别处理相应的进度信息，错误信息，以及完成后的操作
        let errorCb = function (err) {
            console.log("errorCb", err)
            error(err)
        }
        let complete = function (res) {
            console.log("complete ", res)
            let progressData = {}
            progressData.keyUUID = uuid
            progressData.progress = 100
            progressData.key = key
            progressData.filename = file.name
            progressData.uploadFinish = true
            progressData.Location = getStorageCDNHost() + '/' + key
            progressData.objectId = response.objectId
            callback(progressData)
        }
        let next = function (response) {
            let chunks = response.chunks || [];
            let total = response.total;
            // 这里对每个chunk更新进度，并记录已经更新好的避免重复更新，同时对未开始更新的跳过
            for (let i = 0; i < chunks.length; i++) {
                if (chunks[i].percent === 0 || finishedAttr[i]) {
                    continue;
                }
                if (compareChunks[i].percent === chunks[i].percent) {
                    continue;
                }
                if (chunks[i].percent === 100) {
                    finishedAttr[i] = true;
                }
            }
            console.log("qiniuUpload progress", total.percent)
            compareChunks = chunks;

            let progressData = {}
            progressData.keyUUID = uuid
            progressData.progress = Math.round(total.percent)
            progressData.key = key
            progressData.filename = file.name
            progressData.uploadFinish = false
            progressData.Location = ""
            callback(progressData)
        }
        let subObject = {
            next: next,
            error: errorCb,
            complete: complete
        };
        let putExtra = {
            customVars: {}
        };
        let config = {
            useCdnDomain: true,
            disableStatisticsReport: false,
            retryCount: 6,
            region: qiniu.region.z0,
            debugLogLevel: 'INFO'
        };
        // 调用sdk上传接口获得相应的observable，控制上传和暂停
        let observable = qiniu.upload(file, key, upToken, putExtra, config);
        observable.subscribe(subObject);
    }
}
